
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import DttTypo from '@/components/Typo/index.vue';
import { IconName } from '@/components/Icon/types';
import DttIcon from '@/components/Icon/index.vue';

type ButtonType = 'main' | 'plain' | 'text';
type ButtonSize = 'lg' | 'md' | 'sm' | 'xs';

@Component({
  name: 'DttButton',
  components: { DttIcon, DttTypo },
})
export default class Button extends Vue {
  @Prop({ type: String, default: 'main' }) type!: ButtonType;
  @Prop({ type: String, default: 'button' }) nativeType!: string;
  @Prop({ type: String, default: 'md' }) size!: ButtonSize;
  @Prop({ type: Boolean, default: false }) rounded!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: String }) iconLeft?: IconName;
  @Prop({ type: String }) iconRight?: IconName;

  @Emit()
  click(e: PointerEvent) {
    return e;
  }

  get modifiersClasses() {
    return [
      `dtt-button--${this.type}`,
      `dtt-button--${this.size}`,
      this.rounded && `dtt-button--rounded`,
    ];
  }

  get iconSize() {
    switch (this.size) {
      case 'xs':
        return '16px';
      case 'sm':
        return '20px';
      case 'md':
        return '20px';
      case 'lg':
        return '24px';
      default:
        return '24px';
    }
  }
}
