
import { Component, ModelSync, Prop, Vue } from 'vue-property-decorator';
import DttInput from '@/components/Input/index.vue';
import { AutoCompleteItem } from '@/components/AutoComplete/types';
import { Watch } from 'vue-property-decorator';

@Component({
  name: 'DttAutoComplete',
  components: { DttInput },
})
export default class AutoComplete extends Vue {
  @ModelSync('value', 'change', { default: null })
  modelValue!: string | number | null;
  @Prop({ type: Array, default: () => [] })
  options!: AutoCompleteItem[];

  blockPosition = 'bottom';

  searchItem = '';

  isShowList = false;

  get optionsList() {
    return this.options.filter((i) =>
      i.label.toLowerCase().includes(this.searchItem.toLowerCase())
    );
  }

  selectItem(item: AutoCompleteItem) {
    this.modelValue = item.value;

    this.searchItem = item.label;

    this.isShowList = false;
  }

  onShowList() {
    this.isShowList = true;
    this.$nextTick(() => {
      if (
        (this.$refs.el as HTMLElement)?.getBoundingClientRect().bottom + 250 <=
        (window.innerHeight || document.documentElement.clientHeight)
      ) {
        this.blockPosition = 'bottom';
      } else {
        this.blockPosition = 'top';
      }
    });
  }

  outsideClickHandler(e: PointerEvent) {
    const el = this.$refs.el as Element;
    if (!el.contains(e.target as Node)) {
      this.isShowList = false;
    }
  }

  @Watch('searchItem')
  fn(val: string) {
    if (!val) {
      this.modelValue = null;
    }
  }

  @Watch('modelValue')
  fn1(val: string) {
    if (val) {
      this.searchItem = val;
    }
  }

  mounted() {
    //@ts-ignore
    document.addEventListener('click', this.outsideClickHandler);
    if (this.modelValue) {
      this.searchItem = this.modelValue as string;
    }
  }

  beforeDestroy() {
    //@ts-ignore
    document.removeEventListener('click', this.outsideClickHandler);
  }
}
