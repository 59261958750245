
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import DttIcon from '@/components/Icon/index.vue';

@Component({
  name: 'DttAccordion',
  components: { DttIcon },
})
export default class Accordion extends Vue {
  @Prop({ type: String }) title?: string;

  @Emit()
  onStatusChange() {
    return this.isShowContent;
  }

  isShowContent = false;

  toggleContentStatus() {
    this.isShowContent = !this.isShowContent;
    this.onStatusChange();
  }
}
