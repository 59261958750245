// @ts-nocheck
import defaultLang from './lang/ru.json';
import Vue from 'vue';
import deepmerge from 'deepmerge';
import Format from './format';

const format = Format(Vue);
let lang = defaultLang;
let merged = false;
let i18nHandler = function (this: any) {
  const vuei18n = Object.getPrototypeOf(this || Vue).$t;
  if (typeof vuei18n === 'function' && !!Vue.locale) {
    if (!merged) {
      merged = true;
      Vue.locale(
        Vue.config.lang,
        deepmerge(lang, Vue.locale(Vue.config.lang) || {}, { clone: true })
      );
    }
    // eslint-disable-next-line prefer-rest-params
    return vuei18n.apply(this, arguments);
  }
};

export const t = function (
  this: {
    use: (l: any) => void;
    t: (path: any, options: any) => any;
    i18n: (fn: any) => void;
  },
  path: any,
  options: any
) {
  // eslint-disable-next-line prefer-rest-params
  let value = i18nHandler.apply(this, arguments);
  if (value !== null && value !== undefined) return value;

  const array = path.split('.');
  let current = lang;

  for (let i = 0, j = array.length; i < j; i++) {
    const property = array[i];
    value = current[property];
    if (i === j - 1) return format(value, options);
    if (!value) return '';
    current = value;
  }
  return '';
};

export const use = function (l: any) {
  lang = l || lang;
};

export const i18n = function (fn: any) {
  i18nHandler = fn || i18nHandler;
};

export default { use, t, i18n };
