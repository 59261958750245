
import { Component, ModelSync, Prop, Vue } from 'vue-property-decorator';
import DttTypo from '@/components/Typo/index.vue';
import { CheckboxSize, CheckboxMode } from '@/components/Checkbox/types';

@Component({
  name: 'DttCheckbox',
  components: { DttTypo },
})
export default class Checkbox extends Vue {
  @Prop({ type: String, default: 'md' }) size!: CheckboxSize;
  @Prop({ type: String }) mode?: CheckboxMode;
  @ModelSync('value', 'change', { type: Boolean, default: false })
  modelValue!: boolean;

  get classes() {
    return [
      `dtt-checkbox--${this.size}`,
      !this.$slots.default && `dtt-checkbox--empty`,
      this.mode === 'toggle' && 'dtt-checkbox-toggle',
    ];
  }
}
