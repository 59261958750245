
import { Component, Prop, Vue } from 'vue-property-decorator';
import DttTypo from '@/components/Typo/index.vue';
import DttButton from '@/components/Button/index.vue';
import DttTitle from '@/components/Title/index.vue';
import DttInput from '@/components/Input/index.vue';
import DttIcon from '@/components/Icon/index.vue';
import Locale from '@/mixins/Locale';

@Component({
  name: 'DttModal',
  components: { DttIcon, DttInput, DttTitle, DttButton, DttTypo },
  mixins: [Locale],
})
export default class Modal extends Vue {
  @Prop({ type: String, required: false }) header?: string;
  @Prop({ type: Boolean, required: true }) visible!: boolean;
  @Prop({ type: String, default: 'fit-content' }) width!: string;
  @Prop({ type: String, default: '10%' }) top!: string;
  @Prop({ type: String, default: '10%' }) bottom!: string;

  overlayClick(e: PointerEvent) {
    if (this.$refs.layout !== e.target) return;
    this.$emit('close');
  }
}
