import { t } from '@/locale';

export default {
  methods: {
    t(...args: any[]): any {
      // @ts-ignore
      return t.apply(this, args);
    },
  },
};
