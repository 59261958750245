const RE_NARGS = /(%|)\{([0-9a-zA-Z_]+)\}/g;

function hasOwn(obj: any, key: any) {
  return Object.prototype.hasOwnProperty.call(obj, key);
}

export default function (Vue: any) {
  function template(string: any, ...args: any) {
    if (args.length === 1 && typeof args[0] === 'object') {
      args = args[0];
    }

    if (!args || !args.hasOwnProperty) {
      args = {};
    }

    return string.replace(
      RE_NARGS,
      (match: any, prefix: any, i: any, index: any) => {
        let result;

        if (string[index - 1] === '{' && string[index + match.length] === '}') {
          return i;
        } else {
          result = hasOwn(args, i) ? args[i] : null;
          if (result === null || result === undefined) {
            return '';
          }

          return result;
        }
      }
    );
  }

  return template;
}
