
import { Component, Prop, Vue } from 'vue-property-decorator';

type TitleTags = `h${'1' | '2' | '3' | '4' | '5' | '6'}`;

@Component({
  name: 'DttTitle',
})
export default class Title extends Vue {
  @Prop({ type: String, default: 'h1' }) tag!: TitleTags;
}
