
import { Component, ModelSync, Prop, Vue } from 'vue-property-decorator';
import DttTypo from '@/components/Typo/index.vue';
import {
  RadioButtonData,
  RadioButtonDirection,
  RadioButtonSize,
} from '@/components/RadioButton/types';
import { PropType } from 'vue';

@Component({
  name: 'DttRadioBtn',
  components: { DttTypo },
})
export default class RadioButton extends Vue {
  @Prop({ type: String, default: 'md' }) size!: RadioButtonSize;
  @Prop({
    type: Array as PropType<{ value: string; label: string }[]>,
    required: true,
  })
  data!: RadioButtonData;
  @Prop({ type: String, default: 'row' })
  direction?: RadioButtonDirection;
  @Prop({ type: String, default: '2.4rem' }) gap?: string;
  @ModelSync('value', 'update:value', { type: [String, Number] })
  modelValue!: string | number;

  get styles() {
    return {
      'flex-direction': this.direction,
      ...(this.gap && { gap: this.gap }),
    };
  }
}
