
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import DttButton from '@/components/Button/index.vue';
import DttCheckbox from '@/components/Checkbox/index.vue';
import DttIcon from '@/components/Icon/index.vue';
import Locale from '@/mixins/Locale';

@Component({
  name: 'DttItemSelector',
  components: { DttIcon, DttCheckbox, DttButton },
  mixins: [Locale],
})
export default class ItemSelector extends Vue {
  @Prop({ type: Array, required: true }) selectItems!: Array<number | string>;
  @Prop({ type: Boolean, default: false }) onlyDownload!: boolean;
}
