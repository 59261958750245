
import { Component, Prop, Vue } from 'vue-property-decorator';

type TypoSize = 'lg' | 'md' | 'sm' | 'xs';

@Component({
  name: 'DttTypo',
})
export default class Typo extends Vue {
  @Prop({ type: String, default: 'p' }) tag!: string;
  @Prop({ type: String, default: 'md' }) size!: TypoSize;
  @Prop({ type: Boolean, default: false }) bold!: boolean;
}
