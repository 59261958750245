
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'DttTableColumn',
})
export default class TableColumn extends Vue {
  @Prop({ required: true }) header?: string | any;
  @Prop({ type: String, required: true }) prop?: string;
  @Prop({ type: String, default: '100%' }) width!: string;
  @Prop({ type: String, default: '100px' }) minWidth!: string;
  @Prop({ type: String, default: 'left' }) cellAlign!: string;
  @Prop({ type: String, default: '' }) cellClass!: string;
  @Prop({ type: String, default: 'left' }) headerAlign!: string;
  @Prop({ type: String, default: '' }) headerClass!: string;
  @Prop({ type: Boolean, default: false }) sortable!: boolean;
  @Prop({ type: Boolean, default: false }) cellDivider!: boolean;
  @Prop({ type: Boolean, default: false }) headerDivider!: boolean;
  @Prop({ type: Boolean, default: false }) filterActive!: boolean;

  @Inject('dtt-table-driver')
  driver!: {
    add: (column: TableColumn) => void;
    remove: (column: TableColumn) => void;
  };

  get colProps() {
    return {
      style: {
        width: this.width,
        minWidth: this.minWidth,
      },
    };
  }

  get thProps() {
    return {
      divider: this.headerDivider,
      align: this.headerAlign,
      class: this.headerClass,
    };
  }

  get tdProps() {
    return {
      divider: this.cellDivider,
      align: this.cellAlign,
      class: this.cellClass,
    };
  }

  get state() {
    return {
      header: this.header,
      prop: this.prop,
      sortable: this.sortable,
      filterActive: this.filterActive,
      colProp: this.colProps,
      tdProps: this.tdProps,
      thProps: this.thProps,
    };
  }

  created() {
    this.driver.add(this);
  }

  beforeDestroy() {
    this.driver.remove(this);
  }
}
