
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import DttIcon from '@/components/Icon/index.vue';
import { IconName } from '@/components/Icon/types';
import DttTypo from '@/components/Typo/index.vue';
import { BadgeSize, BadgeType } from '@/components/Badge/types';

@Component({
  name: 'DttBadge',
  components: { DttTypo, DttIcon },
})
export default class Badge extends Vue {
  @Prop({ type: String }) icon?: IconName;
  @Prop({ type: String, default: 'md' }) size!: BadgeSize;
  @Prop({ type: String, default: 'primary' }) type!: BadgeType;
  @Prop({ type: Boolean, default: false }) rounded!: boolean;
  @Prop({ type: Boolean, default: false }) closable!: boolean;
  @Emit()
  close(e: PointerEvent) {
    return e;
  }

  get modifierClasses() {
    return [
      `dtt-badge--${this.size}`,
      `dtt-badge--${this.type}`,
      this.rounded && `dtt-badge--rounded`,
    ];
  }

  get iconSize() {
    switch (this.size) {
      case 'xs':
        return '16px';
      case 'sm':
        return '20px';
      case 'md':
        return '24px';
      case 'lg':
        return '24px';
      default:
        return '24px';
    }
  }
}
